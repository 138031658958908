@import "src/styles/_variables";

/* ===== Sidebar ===== */
.sidebar {
  position: fixed;
  top: 75px;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 10px 14px;
  padding-right: 0px;
  background: $sidebar-color;
  transition: $tran-05;
  z-index: 100;
  border: 1px solid $sidebar-border-color;
}
.sidebar.close {
  width: 100px;
}

/* ===== Reusable code - Here ===== */
.sidebar li {
  height: 40px;
  list-style: none;
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.sidebar header .image,
.sidebar {
  min-width: 60px;
  border-radius: 6px;
}

.left-section-text-color {
  color: $text-color;
}

.count-text {
  text-align: center;
  margin-left: 10px;
  padding-top: 4px;
  height: 20px;
  width: 22px;
  border-radius: 8px;
  text-align: center;
  background: $dashboard-color;
  color: $white;
}

.sidebar .text {
  font-size: 14px !important;
  font-weight: 500;
  white-space: nowrap;
  opacity: 1;
}
.sidebar.close .text {
  opacity: 0;
}
/* =========================== */

.sidebar header {
  position: relative;
}

.sidebar header .image {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $tran-05;
}

.sidebar header .image img {
  border-radius: 6px;
}

.sidebar header .toggle {
  position: absolute;
  top: 22rem;
  right: -15px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background-color: $sidebar-option-background;
  color: $sidebar-color;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: $tran-05;
}

.sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}
.sidebar-bottom {
  padding: 1rem;
  padding-top: 0.4rem;
  padding-left: 0.5rem;
}
.close-sidebar-bottom {
  padding: 1rem;
  padding-top: 0.4rem;
  padding-left: 0;
}
.sidebar .menu {
  .sidebar-horizontal-line {
    margin-top: 9rem;
    margin-right: 1rem;
    border-color: $horizontal-line;
  }

  .menu-group {
    font-size: 14px;
    letter-spacing: 2px;
    margin-left: -1.7rem;
    color: $sidebar-group-text-color;
    font-weight: 800;
  }
  .message-group {
    font-size: 14px;
    letter-spacing: 2px;
    margin-left: -1.7rem;
    color: $sidebar-group-text-color;
    font-weight: 800;
  }
  .title-top {
    font-size: 12px;
    color: $title-color;
  }
  .bottom-container {
    display: flex;
    justify-content: space-between;
    margin: auto;
    .bottom-left {
      display: flex;
      gap: 1rem;
    }
    .icon-left {
      width: 20px;
      height: 20px;
      background-color: $sidebar-option-background;
      border-radius: 5px;
    }
    .right-number-container {
      background-color: $notification-background;
      padding-left: 10px;
      padding-right: 10px;
      display: grid;
      align-items: center;
      border-radius: 10px;
      font-size: 12px;
      color: $sidebar-color;
    }
  }
}

.sidebar li.search-box {
  border-radius: 6px;
  background-color: $primary-color-light;
  cursor: pointer;
  transition: $tran-05;
}

.sidebar li a {
  list-style: none;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: $tran-03;
  position: relative;
  overflow: hidden;
  right: 30px;
}
.sidebar li a::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  padding: 50%;
  width: 32px;
  height: 32px;
  background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 1s, transform 0.5s;
}
.sidebar li a:active:after {
  opacity: 0.32;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0s;
}
.sidebar li a:hover {
  background-color: $light-gray-color;
}

.sidebar .menu-bar {
  .menu {
    .menu-links {
      .content-height {
        margin-top: 15px;
        margin-bottom: 1.5rem;

        .menu-group {
          margin-bottom: 0.5rem;
        }

        .horizontal-line {
          margin-left: -2rem;
          width: 100%;
          margin-top: 1rem;
          margin-bottom: 2rem;
          border-color: $light-grey;
          opacity: 0.4;
        }
      }
      .nav-link {
        .icon {
          min-width: 60px;
          border-radius: 6px;
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px !important;
          color: $top-header-color;
        }

        .selected-icon {
          min-width: 60px;
          border-radius: 6px;
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px !important;
          color: $white !important;
        }
        .selected-link {
          color: $sidebar-color !important;
          background-color: $banner-color !important;
        }
      }
    }
  }
}

.sidebar .menu-bar {
  .horizontal-line {
    width: 90%;
    margin-right: 1rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .menu-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
  }
  .message-group {
    margin-bottom: 1rem;
    margin-left: 1px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
  .menu-text {
    font-weight: 800;
  }
  .sidebar-msg-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .sidebar-msg-text {
    padding-right: 1rem;
    padding-top: 0.4rem;
    font-size: 14px;
    border-top: 1px solid $toggle-color;
  }

  .last-msg {
    color: $color-red;
    font-weight: bold;
  }
}

.sidebar .menu-bar {
  height: calc(100% - 75px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}
.menu-bar::-webkit-scrollbar {
  display: none;
}

.menu-bar .bottom-content .toggle-switch {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: $toggle-color;
  transition: $tran-05;
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: $sidebar-color;
  transition: $tran-04;
}

.sidebar.close ~ .home {
  left: 100px;
  width: calc(100% - 100px);
}

.hide {
  display: none !important;
}
